var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.reservationsData.itineraries ? _c('div', [['1S', 'VU', 'QH', 'VJ'].includes(_vm.reservationsData.source) ? _c('div', {
    staticClass: "d-flex-center justify-content-end mb-50"
  }, [_c('b-button', {
    staticClass: "px-75 py-50 rounded-lg text-nowrap",
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.openLinkFareRules(_vm.reservationsData.source);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.linkFareRule')) + " ")])], 1) : _vm._e(), _vm._l(_vm.reservationsData.itineraries, function (trips, tripIndex) {
    return _c('div', {
      key: tripIndex
    }, [_c('b-card', {
      staticClass: "border-warning",
      attrs: {
        "header-bg-variant": "warning",
        "header-class": "warning",
        "body-class": "py-75 px-0"
      }
    }, [_c('app-collapse', [_c('app-collapse-item', {
      attrs: {
        "is-visible": _vm.reservationsData.itineraries.length <= 2 ? true : tripIndex === 0 ? true : false,
        "title": "",
        "body-class": "px-0"
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex align-items-center w-100"
          }, [_c('span', {
            staticClass: "text-dark font-weight-bolder font-medium-4"
          }, [_vm._v("Hành trình " + _vm._s(_vm.reservationsData.itineraries.length > 1 ? tripIndex + 1 : ''))])])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(trips, function (segment, segmentIndex) {
      return _c('b-row', {
        key: segmentIndex,
        staticClass: "mx-0 pt-1 border-top"
      }, [_c('b-col', {
        attrs: {
          "cols": "12"
        }
      }, [trips.length > 1 ? _c('code', [_vm._v(" Chặng bay " + _vm._s(trips.length > 1 ? segmentIndex + 1 : '') + ": "), _c('span', {
        staticClass: "font-weight-bolder"
      }, [_vm._v(" " + _vm._s("".concat(segment.departure.iataCode, " - ").concat(segment.arrival.iataCode)) + " ")])]) : _vm._e()]), _c('b-col', {
        attrs: {
          "cols": "12"
        }
      }, [_c('b-card', [_c('div', {}, [segment.status ? _c('b-row', {
        staticClass: "pb-25"
      }, [_c('span', {
        staticClass: "mr-2"
      }, [_vm._v(" " + _vm._s(_vm.$t('reservation.flightsDetails.status')) + ": ")]), _c('span', {
        staticClass: "fw-600"
      }, [_vm._v(" " + _vm._s(segment.status) + " ")])]) : _vm._e(), segment.bookingClass || segment.groupClass ? _c('b-row', {
        staticClass: "pb-25"
      }, [_c('span', {
        staticClass: "mr-2"
      }, [_vm._v(" " + _vm._s(_vm.$t('reservation.flightsDetails.bookingClass')) + ": ")]), segment.fareBasisCode ? [_vm._v(" ( "), _c('span', {
        staticClass: "fw-600 ml-25"
      }, [_vm._v(" " + _vm._s(segment.groupClass || _vm.getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName) + " ")]), segment.bookingClass && (segment.groupClass || _vm.getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName) ? _c('span', {
        staticClass: "px-25\""
      }, [_vm._v(" | ")]) : _vm._e(), _c('span', {
        staticClass: "fw-600"
      }, [_vm._v(" " + _vm._s(segment.bookingClass) + " ")]), segment.fareBasisCode ? _c('span', {
        staticClass: "fw-600 text-danger mx-25"
      }, [_vm._v(" " + _vm._s(segment.fareBasisCode) + " ")]) : _vm._e(), _vm._v(" ) ")] : [_c('span', {
        staticClass: "fw-600"
      }, [_vm._v(" " + _vm._s(segment.groupClass || _vm.getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName) + " ")]), segment.bookingClass && (segment.groupClass || _vm.getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName) ? _c('span', {
        staticClass: "px-25\""
      }, [_vm._v(" | ")]) : _vm._e(), _c('span', {
        staticClass: "fw-600"
      }, [_vm._v(" " + _vm._s(segment.bookingClass) + " ")]), _c('span', {
        staticClass: "fw-600 text-danger ml-25"
      }, [_vm._v(" " + _vm._s(segment.fareBasisCode) + " ")])]], 2) : _vm._e(), (segment.bookingClass || segment.groupClass) && segment.airline !== 'QH' || segment.groupClass && segment.airline === 'QH' ? _c('b-row', {
        staticClass: "pb-25"
      }, [_c('span', {
        staticClass: "mr-2"
      }, [_vm._v(" Hoàn vé: ")]), _c('span', {
        staticClass: "font-weight-bold text-wrap"
      }, [_vm._v(" " + _vm._s(['', 'NONE', false].includes(_vm.getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).refund) ? 'Không được hoàn' : _vm.getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).refund) + " ")])]) : _vm._e(), (segment.bookingClass || segment.groupClass) && segment.airline !== 'QH' || segment.groupClass && segment.airline === 'QH' ? _c('b-row', {
        staticClass: "pb-25"
      }, [_c('span', {
        staticClass: "mr-2"
      }, [_vm._v(" Đổi ngày: ")]), _c('span', {
        staticClass: "font-weight-bold text-wrap"
      }, [_vm._v(" " + _vm._s(['', 'NONE', false].includes(_vm.getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).reschedule) ? 'Không' : _vm.getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).reschedule) + " ")])]) : _vm._e(), (segment.bookingClass || segment.groupClass) && segment.airline !== 'QH' || segment.groupClass && segment.airline === 'QH' ? _c('b-row', {
        staticClass: "text-wrap pb-25",
        attrs: {
          "title": _vm.getBaggageInBookingDetail(segment, _vm.reservationsData).carryBag
        }
      }, [_c('span', {
        staticClass: "mr-2"
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.handBaggage')) + ": ")]), _c('span', {
        staticClass: "font-weight-bold"
      }, [_vm._v(" " + _vm._s(_vm.getBaggageInBookingDetail(segment, _vm.reservationsData).carryBag) + " ")])]) : _vm._e(), (segment.bookingClass || segment.groupClass) && segment.airline !== 'QH' || segment.groupClass && segment.airline === 'QH' ? _c('b-row', {
        staticClass: "text-wrap",
        attrs: {
          "title": _vm.getBaggageInBookingDetail(segment, _vm.reservationsData).checkinBag
        }
      }, [_c('span', {
        staticClass: "mr-2"
      }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.freeBaggage')) + ": ")]), _c('span', {
        staticClass: "font-weight-bold"
      }, [_vm._v(" " + _vm._s(_vm.getBaggageInBookingDetail(segment, _vm.reservationsData).checkinBag))])]) : _vm._e()], 1)])], 1)], 1);
    }), 1)], 1)], 1)], 1);
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div v-if="reservationsData.itineraries">
      <div
        v-if="['1S', 'VU', 'QH', 'VJ'].includes(reservationsData.source)"
        class="d-flex-center justify-content-end mb-50"
      >
        <b-button
          variant="outline-info"
          class="px-75 py-50 rounded-lg text-nowrap"
          @click.prevent.stop="openLinkFareRules(reservationsData.source)"
        >
          {{ $t('reservation.linkFareRule') }}
        </b-button>
      </div>
      <div
        v-for="(trips, tripIndex) of reservationsData.itineraries"
        :key="tripIndex"
      >
        <!-- <b-card
          header-bg-variant="light-info"
          header-class="p-1 font-weight-bolder text-airline"
          body-class="pb-0"
        >
          <template #header>
            <div>
              Hành trình {{ reservationsData.itineraries.length > 1 ? tripIndex + 1 : '' }}:
            </div>
          </template> -->
        <b-card
          header-bg-variant="warning"
          header-class="warning"
          body-class="py-75 px-0"
          class="border-warning"
        >
          <app-collapse>
            <app-collapse-item
              :is-visible="reservationsData.itineraries.length <= 2 ? true : tripIndex === 0 ? true : false"
              title
              body-class="px-0"
            >
              <!-- ANCHOR - Collapse HEADER -->
              <template #header>
                <div class="d-flex align-items-center w-100">
                  <span class="text-dark font-weight-bolder font-medium-4">Hành trình {{ reservationsData.itineraries.length > 1 ? tripIndex + 1 : '' }}</span>
                </div>
              </template>

              <!-- SECTION Collapse BODY -->
              <b-row
                v-for="(segment, segmentIndex) of trips"
                :key="segmentIndex"
                class="mx-0 pt-1 border-top"
              >
                <b-col
                  cols="12"
                >
                  <code v-if="trips.length > 1">
                    Chặng bay {{ trips.length > 1 ? segmentIndex + 1 : '' }}:
                    <span class="font-weight-bolder">
                      {{ `${segment.departure.iataCode} - ${segment.arrival.iataCode}` }}
                    </span>
                  </code>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-card>
                    <div class="">
                      <b-row
                        v-if="segment.status"
                        class="pb-25"
                      >
                        <span
                          class="mr-2"
                        >
                          {{ $t('reservation.flightsDetails.status') }}:
                        </span>
                        <span
                          class="fw-600"
                        >
                          {{ segment.status }}
                        </span>
                      </b-row>

                      <b-row
                        v-if="segment.bookingClass || segment.groupClass"
                        class="pb-25"
                      >
                        <span
                          class="mr-2"
                        >
                          {{ $t('reservation.flightsDetails.bookingClass') }}:
                        </span>

                        <template v-if="segment.fareBasisCode">
                          (
                          <span class="fw-600 ml-25">
                            {{ segment.groupClass || getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName }}
                          </span>
                          <span
                            v-if="segment.bookingClass && (segment.groupClass || getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName)"
                            class="px-25&quot;"
                          >
                            |
                          </span>
                          <span class="fw-600">
                            {{ segment.bookingClass }}
                          </span>
                          <span
                            v-if="segment.fareBasisCode"
                            class="fw-600 text-danger mx-25"
                          >
                            {{ segment.fareBasisCode }}
                          </span>
                          )
                        </template>

                        <template v-else>
                          <span class="fw-600">
                            {{ segment.groupClass || getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName }}
                          </span>
                          <span
                            v-if="segment.bookingClass && (segment.groupClass || getFareRulesAirlineByBookingClass(segment.airline, segment.bookingClass).cabinName)"
                            class="px-25&quot;"
                          >
                            |
                          </span>
                          <span class="fw-600">
                            {{ segment.bookingClass }}
                          </span>
                          <span
                            class="fw-600 text-danger ml-25"
                          >
                            {{ segment.fareBasisCode }}
                          </span>
                        </template>
                      </b-row>

                      <b-row
                        v-if="((segment.bookingClass || segment.groupClass) && segment.airline !== 'QH') || (segment.groupClass && segment.airline === 'QH')"
                        class="pb-25"
                      >
                        <span
                          class="mr-2"
                        >
                          Hoàn vé:
                        </span>
                        <span
                          class="font-weight-bold text-wrap"
                        >
                          {{
                            ['', 'NONE', false].includes(
                              getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline)
                                ? segment.groupClass
                                : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).refund
                            )
                              ? 'Không được hoàn'
                              : getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline)
                                ? segment.groupClass
                                : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).refund
                          }}
                        </span>
                      </b-row>

                      <b-row
                        v-if="((segment.bookingClass || segment.groupClass) && segment.airline !== 'QH') || (segment.groupClass && segment.airline === 'QH')"
                        class="pb-25"
                      >
                        <span
                          class="mr-2"
                        >
                          Đổi ngày:
                        </span>
                        <span class="font-weight-bold text-wrap">
                          {{
                            ['', 'NONE', false].includes(getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).reschedule) ? 'Không' : getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).reschedule
                          }}
                        </span>
                      </b-row>

                      <!-- <b-row
                        v-if="((segment.bookingClass || segment.groupClass) && segment.airline !== 'QH') || (segment.groupClass && segment.airline === 'QH')"
                        class="pb-25"
                      >
                        <span
                          class="mr-2"
                        >
                          goShow:
                        </span>
                        <span class="font-weight-bold text-wrap">
                          {{ getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).goShow === 'NONE' ? 'Không' : 'Có phí' }}
                        </span>
                      </b-row> -->

                      <!-- <b-row
                        v-if="((segment.bookingClass || segment.groupClass) && segment.airline !== 'QH') || (segment.groupClass && segment.airline === 'QH')"
                        class="pb-25"
                      >
                        <span
                          class="mr-2"
                        >
                          Chọn chỗ ngồi:
                        </span>
                        <span class="font-weight-bold text-wrap">
                          {{ getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).seat }}
                        </span>
                      </b-row> -->

                      <!-- <b-row
                        v-if="((segment.bookingClass || segment.groupClass) && segment.airline !== 'QH') || (segment.groupClass && segment.airline === 'QH')"
                        class="pb-25"
                      >
                        <span
                          class="mr-2"
                        >
                          Ưu tiên làm thủ tục:
                        </span>
                        <span class="font-weight-bold text-wrap">
                          {{ getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).checkinPriority === 'NO' ? 'Không bao gồm' : 'Có phí' }}
                        </span>
                      </b-row> -->

                      <!-- <b-row
                        v-if="((segment.bookingClass || segment.groupClass) && segment.airline !== 'QH') || (segment.groupClass && segment.airline === 'QH')"
                        class="pb-25"
                      >
                        <span
                          class="mr-2"
                        >
                          Phòng chờ:
                        </span>
                        <span class="font-weight-bold text-wrap">
                          {{ getFareRulesAirlineByBookingClass(segment.airline, ['VJ', 'QH'].includes(segment.airline) ? segment.groupClass : ['VU'].includes(segment.airline) ? segment.fareType : segment.bookingClass).loungeService === 'NO' ? 'Không bao gồm' : 'Có phí' }}
                        </span>
                      </b-row> -->

                      <!-- NOTE hanh ly mien cuoc -->
                      <!-- NOTE XACH TAY -->
                      <b-row
                        v-if="((segment.bookingClass || segment.groupClass) && segment.airline !== 'QH') || (segment.groupClass && segment.airline === 'QH')"
                        class="text-wrap pb-25"
                        :title="getBaggageInBookingDetail(segment, reservationsData).carryBag"
                      >
                        <span
                          class="mr-2"
                        >
                          {{ $t('ticket.flightsInfo.handBaggage') }}:
                        </span>
                        <span
                          class="font-weight-bold"
                        >
                          {{ getBaggageInBookingDetail(segment, reservationsData).carryBag }}
                        </span>
                      </b-row>

                      <!-- NOTE KY GUI -->
                      <b-row
                        v-if="((segment.bookingClass || segment.groupClass) && segment.airline !== 'QH') || (segment.groupClass && segment.airline === 'QH')"
                        :title="getBaggageInBookingDetail(segment, reservationsData).checkinBag"
                        class="text-wrap"
                      >
                        <span
                          class="mr-2"
                        >
                          {{ $t('ticket.flightsInfo.freeBaggage') }}:
                        </span>
                        <span
                          class="font-weight-bold"
                        >
                          {{ getBaggageInBookingDetail(segment, reservationsData).checkinBag }}</span>
                      </b-row>
                    </div>

                  </b-card>
                </b-col>
              </b-row>
              <!-- !SECTION -->
            </app-collapse-item>
          </app-collapse>
        </b-card>

        <!-- </b-card> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

import store from '@/store'

import useReservationHandle from '@reservation/useReservationHandle'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      getFareRulesAirlineByBookingClass,
      getBaggageInBookingDetail,
      currentBreakpoint,
    } = useReservationHandle()

    function openLinkFareRules(source) {
      const link = store.getters['globalConfig/getLinkFareRules'](source)
      window.open(link)
    }

    return {
      getFareRulesAirlineByBookingClass,
      getBaggageInBookingDetail,
      currentBreakpoint,
      openLinkFareRules,
    }
  },
}
</script>

<style lang="scss">

</style>
